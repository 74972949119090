/* Colors */

html{
  scroll-behavior: smooth;

}


:root {
  --primary-color: #3498db;
  --background-color: #fff;
}

/* Reset and General Styles */
body {
  margin: 0;
  padding: 0;
  min-width: 100%;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* display: flex;
  flex-direction: column; */
  justify-content: center;
  align-items: center;
  background-color:#e3e8ed;
  scroll-behavior: smooth;

}

#root {
  width: 100%;
  height: 100%;
}

button,
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Code Styling */
code {
  color: var(--primary-color);
}
