/* error page */
.err-container {
  width: 100%;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.err-container h1,
.err-container h2 {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  text-decoration: none;
  text-align: center;
  line-height: 50px;
}

.container a,
.err-container a,
.container a:hover,
.err-container a:hover,
.container a:hover,
.err-container a:active {
  color: black;
  text-decoration: none;
  border-bottom: 4px black dotted;
  padding-bottom: 5px;
  width: max-content;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50vh;
  max-width: 100vw;
}

.btn1 {
  text-align: center;
}

.error-text {
  text-align: center;
  padding: 20px;
}

.error {
  font-size: 1.5rem;
  text-decoration: none;
  padding: 15px;
  background: #000;
  color: #fff;
  border-radius: 10px;
}
