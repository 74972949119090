* {
  box-sizing: border-box;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  height: 900px;
  width: 99vw;
}

input[type="text"],
textarea {
  width: 80%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.btn {
  background-color: #04aa60;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #45a049;
}
@media only screen and (max-width: 768px) {
  .container {
    padding-top: 5rem;
  }
}
